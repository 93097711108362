import { FetchKaminoCreativesParams } from 'features/kamino/connector';
import { useKaminoCreatives } from 'features/kamino/queries';
import { mapKaminoBillboardPlacements, mapKaminoSponsoredProductsPlacements } from 'utils/kamino.util';
import { useKaminoTracking } from '../useKaminoTracking';

export type KaminoConfig = {
  enabled?: boolean;
  flagship?: {
    enabled?: boolean;
  };
  sponsoredProducts?: {
    enabled?: boolean;
  };
};

const useKaminoRetailMedia = (fetchKaminoCreativesParams?: FetchKaminoCreativesParams, config: KaminoConfig = {}) => {
  const { userHasSufficientConsent } = useKaminoTracking();

  const { flagship: flagshipConfig, sponsoredProducts: sponsoredProductsConfig } = config;
  const flagshipEnabled = flagshipConfig?.enabled ?? true;
  const sponsoredProductsEnabled = sponsoredProductsConfig?.enabled ?? true;
  const enabled = (sponsoredProductsEnabled || flagshipEnabled) && userHasSufficientConsent;

  const { data: kaminoResult } = useKaminoCreatives(fetchKaminoCreativesParams, enabled);
  if (!kaminoResult) return { flagshipPlacements: [], sponsoredProductsPlacements: [] };

  const sponsoredProductsPlacements = mapKaminoSponsoredProductsPlacements(kaminoResult?.ads);
  const billboardPlacements = mapKaminoBillboardPlacements(kaminoResult?.ads);

  return {
    billboardPlacements,
    sponsoredProductsPlacements,
  };
};
export default useKaminoRetailMedia;
