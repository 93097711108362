import { SPONSORED_PRODUCTS_FIXED_POSITIONS } from 'constants/kamino';
import {
  BillboardPlacement,
  BillboardRendering,
  KaminoAd,
  KaminoProduct,
  SimplePlacement,
  SimpleProduct,
} from 'types/Kamino';
import { Product } from 'types/Product';

export const mapKaminoProductToSimpleProduct = (product: KaminoProduct, placement: KaminoAd): SimpleProduct => ({
  id: product.id,
  legalInfo: {
    behalf: placement.dsa.brand,
    paid: placement.dsa.buyer,
  },
  onBasketChangeUrl: placement.trackingLinks.clickBeacon,
  onClickUrl: placement.trackingLinks.clickBeacon,
  onLoadUrl: placement.trackingLinks.impBeacon,
  onViewUrl: placement.trackingLinks.viewBeacon,
  onWishlistUrl: placement.trackingLinks.clickBeacon,
});

export const mapSimplePlacement = (placement: KaminoAd): SimplePlacement => ({
  format: placement.format,
  items: placement.products?.map((item) => mapKaminoProductToSimpleProduct(item, placement)),
  legalInfo: {
    behalf: placement.dsa.brand,
    paid: placement.dsa.buyer,
  },
  onClickUrl: placement.trackingLinks.clickBeacon,
  onLoadUrl: placement.trackingLinks.impBeacon,
  onViewUrl: placement.trackingLinks.viewBeacon,
});

export const mapKaminoSponsoredProductsPlacements = (placements: KaminoAd[]) => {
  const filteredPlacements = placements.filter((placement) => placement.format === 'native');
  const sponsoredProductsPlacements: SimplePlacement[] = filteredPlacements.map((placement) =>
    mapSimplePlacement(placement),
  );
  return sponsoredProductsPlacements;
};

export const mapBillboardPlacement = (placement: KaminoAd): BillboardPlacement => ({
  ...mapSimplePlacement(placement),
  rendering: mapBillboardRendering(placement),
});

const mapBillboardRendering = (placement: KaminoAd): BillboardRendering | undefined => {
  const image = placement.assets?.images?.[0];
  if (!image) return;
  const link = image.redirect;
  return {
    backgroundImage: {
      alt: image.id,
      url: image.url,
    },
    link: {
      href: link,
      newTab: true,
    },
  };
};

export const mapKaminoBillboardPlacements = (placements: KaminoAd[]) => {
  const filteredPlacements = placements.filter((placement) => placement.format === 'billboard-display');
  const billboardPlacements: BillboardPlacement[] = filteredPlacements.map((placement) =>
    mapBillboardPlacement(placement),
  );
  return billboardPlacements;
};

export const getSearchResultsWithSponsoredProducts = (products: Product[], sponsoredProducts: Product[]) => {
  if (!products?.length) {
    return [];
  }
  if (!sponsoredProducts?.length) {
    return products;
  }
  // Remove duplicates
  const productsToShow = products.reduce((prev: Product[], product) => {
    const found = sponsoredProducts?.find((sponsoredProduct) => sponsoredProduct?.code === product?.code);

    if (!found) {
      return [...prev, product];
    }

    return prev;
  }, []);

  SPONSORED_PRODUCTS_FIXED_POSITIONS.map((position, index) => {
    const product = sponsoredProducts?.[index];

    if (product) {
      productsToShow.splice(position - 1, 0, product);
    }
  });

  return productsToShow;
};
