import { useProducts } from 'features/product/queries';
import { useKaminoTracking } from 'hooks/useKaminoTracking';
import useRouter from 'hooks/useRouter';
import { useEffect } from 'react';
import { BillboardPlacement } from 'types/Kamino';
import { Product } from 'types/Product';
import { log } from 'utils/loggerUtil';

export const useBillboard = (placements: BillboardPlacement[]) => {
  const { asPath } = useRouter();
  const { pushKaminoBeacon } = useKaminoTracking();

  const billboardProductIds = placements.flatMap((placement) => placement.items.map((product) => product.id)) ?? [];
  const { data: fetchedProducts = [], isLoading } = useProducts({ productCodes: billboardProductIds });

  const mappedPlacements = placements.map((placement) => {
    const items = placement.items.reduce((acc: Product[], item) => {
      const product = fetchedProducts.find((fetchedProduct) => fetchedProduct.code === item.id);
      if (!product) return acc;

      const mappedProduct: Product = {
        ...product,
        isSponsored: true,
        tracking: {
          OnClickBeacon: item.onClickUrl,
        },
      };

      return [...acc, mappedProduct];
    }, []);

    return {
      ...placement,
      items,
    };
  });

  useEffect(() => {
    if (!pushKaminoBeacon) return;
    mappedPlacements.forEach((placement) => {
      if (placement.onLoadUrl) {
        log(`Kamino - Billboard - ${asPath}`, 'pushing OnLoadBeacon for Billboard format', placement.onLoadUrl);
        pushKaminoBeacon(placement.onLoadUrl);
      }

      placement.items.forEach((product) => {
        if (product.tracking?.OnLoadBeacon) {
          log(
            `Kamino - Billboard - ${asPath}`,
            `pushing OnLoadBeacon for Billboard product with code: ${product.code}`,
          );
          pushKaminoBeacon(product.tracking?.OnLoadBeacon);
        }
      });
    });
  }, []);

  const onViewBillboard = (placement: BillboardPlacement) => {
    if (!pushKaminoBeacon) return;
    if (placement.onViewUrl) {
      log(`Kamino - Billboard - ${asPath}`, 'pushing OnViewBeacon for Billboard format', placement.onViewUrl);
      pushKaminoBeacon(placement.onViewUrl, { stoptime: 0 });
    }
  };

  const onViewBillboardProduct = (product: Product) => {
    if (!pushKaminoBeacon) return;
    if (product.tracking?.OnViewBeacon) {
      log(`Kamino - Billboard - ${asPath}`, `pushing OnViewBeacon for Billboard product with code: ${product.code}`);
      pushKaminoBeacon(product.tracking.OnViewBeacon);
    }
  };

  const onClickBillboard = (placement: BillboardPlacement) => {
    if (!pushKaminoBeacon) return;
    if (placement.onClickUrl) {
      log(`Kamino - Billboard - ${asPath}`, 'pushing OnClickBeacon for Billboard format', placement.onClickUrl);
      pushKaminoBeacon(placement.onClickUrl, { act: 'redirect', click_zone: 'format' });
    }
  };

  const onClickBillboardProduct = (product: Product) => {
    if (!pushKaminoBeacon) return;
    if (product.tracking?.OnClickBeacon) {
      log(`Kamino - Billboard - ${asPath}`, `pushing OnClickBeacon for Billboard product with code: ${product.code}`);
      pushKaminoBeacon(product.tracking.OnClickBeacon, {
        act: 'product_page',
        click_zone: 'product',
        product_id: product.code,
      });
    }
  };

  const onClickBillboardArrow = (placement: BillboardPlacement, act: 'nav-next' | 'nav-prev') => {
    if (!pushKaminoBeacon) return;
    if (placement.onClickUrl) {
      log(`Kamino - Billboard - ${asPath}`, `pushing OnClickBeacon ${act} for Billboard format`, placement.onClickUrl);
      pushKaminoBeacon(placement.onClickUrl, { act, click_zone: 'format' });
    }
  };

  return {
    isLoading,
    mappedPlacements,
    onClickBillboard,
    onClickBillboardArrow,
    onClickBillboardProduct,
    onViewBillboard,
    onViewBillboardProduct,
  };
};
