import { skipToken, useQuery } from '@tanstack/react-query';
import { kaminoKeys } from '.';
import { fetchKaminoCreatives, FetchKaminoCreativesParams } from './connector';

const useKaminoCreatives = (config?: FetchKaminoCreativesParams, enabled = true) =>
  useQuery({
    enabled: enabled && config !== undefined,
    queryFn: config ? () => fetchKaminoCreatives(config) : skipToken,
    queryKey: kaminoKeys.creatives(config),
  });

export { useKaminoCreatives };
