import ProductsSlider, { ProductSliderProps } from 'components/Product/ProductsSlider/ProductsSlider';
import { useSponsoredProducts } from 'hooks/kamino/useSponsoredProducts';
import { useIntl } from 'react-intl';
import { SimplePlacement } from 'types/Kamino';

type SliderProps = Pick<ProductSliderProps, 'alwaysShowArrows' | 'slidesObject' | 'showSignings'>;

interface SponsoredProductsSliderProps extends SliderProps {
  className?: string;
  id?: string;
  placements: SimplePlacement[];
  showSignings?: boolean;
  showTitle?: boolean;
}

const SponsoredProductsSlider = ({
  id,
  placements,
  showSignings,
  showTitle,
  ...sliderProps
}: SponsoredProductsSliderProps) => {
  const { formatMessage } = useIntl();

  const { isLoading, mappedPlacements } = useSponsoredProducts(placements);
  const sponsoredProducts = mappedPlacements?.flatMap((placement) => placement.items);

  if (!sponsoredProducts?.length) return null;

  return (
    <ProductsSlider
      id={id}
      isLoading={isLoading}
      products={sponsoredProducts}
      showSignings={showSignings}
      title={showTitle ? formatMessage({ id: 'sponsored_products_title' }) : undefined}
      {...sliderProps}
    />
  );
};
export default SponsoredProductsSlider;
