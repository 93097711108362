import Billboard from 'components/Billboard/Billboard';
import { ProductSliderProps } from 'components/Product/ProductsSlider/ProductsSlider';
import SponsoredProductsSlider from 'components/SponsoredProducts/SponsoredProductsSlider';
import { KAMINO_RETAILER_ID, KaminoPageType } from 'constants/kamino';
import { ContentPageParams, HomePageParams } from 'features/kamino/connector';
import useKaminoRetailMedia from 'hooks/kamino/useKaminoRetailMedia';
import { useRouter } from 'hooks/useRouter';
import { IBlockSponsoredProducts } from 'types/ContentfulTypes';
import ContentfulGroupingHeading from '../ContentfulGroupingHeading/ContentfulGroupingHeading';
import ContentfulGroupingWrapper from '../ContentfulGroupingWrapper/ContentfulGroupingWrapper';

const pageTypeMap: Record<IBlockSponsoredProducts['fields']['type'], KaminoPageType> = {
  Custom: 'Universe',
  Home: 'HomePage',
};

interface ContentfulBlockSponsoredProductsProps extends ProductSliderProps {
  className?: string;
  content: IBlockSponsoredProducts;
  hideTitle?: boolean;
}

const ContentfulBlockSponsoredProducts = ({
  alwaysShowArrows = false,
  className,
  content,
  hideTitle,
  slidesObject,
}: ContentfulBlockSponsoredProductsProps) => {
  const { locale } = useRouter();
  const {
    // campaignId,
    category,
    dataType = 'Sponsored Products',
    // filters,
    showSignings,
    title,
    type,
  } = content.fields;

  const pageType = pageTypeMap[type];

  const getEventConfig = () => {
    switch (pageType) {
      case 'HomePage': {
        const config: HomePageParams = {
          language: locale,
          page_type: pageType,
          retailer: KAMINO_RETAILER_ID,
        };

        return config;
      }
      case 'Universe': {
        const config: ContentPageParams = {
          language: locale,
          page_id: category ?? '',
          page_type: pageType,
          retailer: KAMINO_RETAILER_ID,
        };

        return config;
      }
    }
  };

  const { billboardPlacements, sponsoredProductsPlacements } = useKaminoRetailMedia(getEventConfig());
  const hasBillboardPlacements = !!billboardPlacements?.length;
  const hasSponsoredProductsPlacements = !!sponsoredProductsPlacements?.length;

  const showTitle =
    !hideTitle &&
    ((dataType === 'Flagship' && hasBillboardPlacements) ||
      (dataType === 'Sponsored Products' && hasSponsoredProductsPlacements));

  if (!content?.fields) {
    return null;
  }

  const sliderProps = {
    alwaysShowArrows,
    slidesObject,
  };

  return (
    <ContentfulGroupingWrapper className={className}>
      {showTitle && <ContentfulGroupingHeading>{title}</ContentfulGroupingHeading>}

      {dataType === 'Flagship' && hasBillboardPlacements && <Billboard placement={billboardPlacements[0]} />}

      {dataType === 'Sponsored Products' && hasSponsoredProductsPlacements && (
        <SponsoredProductsSlider
          placements={sponsoredProductsPlacements}
          showSignings={showSignings}
          {...sliderProps}
        />
      )}
    </ContentfulGroupingWrapper>
  );
};
export default ContentfulBlockSponsoredProducts;
