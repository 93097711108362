import { useProductQuery } from 'features/product/queries';
import { useKaminoTracking } from 'hooks/useKaminoTracking';
import useRouter from 'hooks/useRouter';
import { useEffect, useRef } from 'react';
import { SimplePlacement } from 'types/Kamino';
import { Product } from 'types/Product';
import { log } from 'utils/loggerUtil';

export const useSponsoredProducts = (placements?: SimplePlacement[]) => {
  const { asPath } = useRouter();
  const { pushKaminoBeacon } = useKaminoTracking();
  const KaminoProductIds = placements?.flatMap((placement) => placement.items.map((product) => product.id)) ?? [];
  const productQuery = KaminoProductIds.map((productId) => `:code:${productId}`).join('');
  const { data: fetchedProducts, isLoading } = useProductQuery({
    pageSize: KaminoProductIds.length,
    query: productQuery,
  });

  const mappedPlacements = placements?.map((placement) => {
    const items = placement.items.reduce((acc: Product[], item) => {
      const product = fetchedProducts?.products?.find((fetchedProduct) => fetchedProduct.code === item.id);
      if (!product) return acc;

      const mappedProduct: Product = {
        ...product,
        isSponsored: true,
        legalInfo: item.legalInfo,
        tracking: {
          OnBasketChangeBeacon: item.onBasketChangeUrl,
          OnClickBeacon: item.onClickUrl,
          OnLoadBeacon: item.onLoadUrl,
          OnViewBeacon: item.onViewUrl,
          OnWishlistBeacon: item.onWishlistUrl,
        },
      };

      return [...acc, mappedProduct];
    }, []);

    return {
      ...placement,
      items,
    };
  });

  const sponsoredProducts = mappedPlacements?.flatMap((placement) => placement.items) ?? [];
  const trackingHasFired = useRef(false);

  useEffect(() => {
    if (!pushKaminoBeacon || trackingHasFired.current) return;
    if (sponsoredProducts?.length) {
      sponsoredProducts.forEach((product) => {
        if (product.tracking?.OnLoadBeacon) {
          log(`Kamino - SP - ${asPath}`, 'pushing OnLoadBeacon for format', product.tracking.OnLoadBeacon);
          pushKaminoBeacon(product.tracking.OnLoadBeacon);
        }
      });
      trackingHasFired.current = true;
    }
  }, [sponsoredProducts]);

  const onViewSponsoredProducts = (placement: SimplePlacement) => {
    if (!pushKaminoBeacon) return;
    if (placement.onViewUrl) {
      log(`Kamino - SP - ${asPath}`, 'pushing OnViewBeacon for format', placement.onViewUrl);
      pushKaminoBeacon(placement.onViewUrl);
    }
  };

  return { isLoading, mappedPlacements, onViewSponsoredProducts, sponsoredProducts };
};
